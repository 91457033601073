import gql from "graphql-tag";

export const getNotificacionesById = gql`
  query getNotificaciones($idPrograma: Int!) {
    getNotificaciones(notificacionLike: { idPrograma: $idPrograma }) {
      idNotificacion
      nombre
      asunto
      template
      puntos
      estado
    }
  }
`;

export const getNotificacionById = gql`
  query getNotificaciones($idNotificacion: Int!) {
    getNotificaciones(notificacionLike: { idNotificacion: $idNotificacion }) {
      idNotificacion
      nombre
      asunto
      template
      puntos
      estado
      cc
      cco
    }
  }
`;

export const updateNotificacion = gql`
  mutation updateNotificacion($notificacion: NotificacionUpdate!) {
    updateNotificacion(notificacion: $notificacion) {
      idNotificacion
    }
  }
`;

export const getNotificacionesByNombre = gql`
  query getNotificaciones($idPrograma: Int!, $nombres: [String]) {
    getNotificaciones(
      notificacionLike: { idPrograma: $idPrograma, nombres: $nombres }
    ) {
      idNotificacion
      nombre
      asunto
      template
      puntos
      estado
    }
  }
`;

export default {
  queries: {
    getNotificacionesById,
    getNotificacionById,
    getNotificacionesByNombre
  },
  mutations: {
    updateNotificacion
  }
};
