import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import { reduxForm, change, getFormMeta, FieldArray } from "redux-form";
import {
  Grid,
  Button,
  Icon,
  Tooltip,
  Paper,
  Typography
} from "@material-ui/core";
import {
  InputTextField,
  ReduxTypeAhead,
  InputNumberGtZeroField
} from "js/components/Shared/Form/Fields";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";

const renderDetallesVenta = ({ fields, productos, disabled }) => (
  <Grid container spacing={16}>
    {productos &&
      productos.length !== 0 &&
      !disabled &&
      fields.length < productos.length && (
        <Tooltip title="Agregar item" placement="right-end">
          <Icon color="secondary" onClick={() => fields.push({})}>
            add_circle
          </Icon>
        </Tooltip>
      )}
    {fields.map((detalle, index) => {
      return (
        <Fragment key={index}>
          <Grid xs={11} item>
            <Paper style={{ width: "100%", padding: "15px" }}>
              <Grid container spacing={16}>
                <Grid item xs={12} md={7}>
                  <ReduxTypeAhead
                    name={`${detalle}.codigoProducto`}
                    label="Producto"
                    suggestions={{ sug: productos }}
                    path={{ path: "sug", value: "value", label: "label" }}
                    placeholder="Producto"
                    validate={requiredValidation}
                    disabled={disabled}
                    required
                  />
                </Grid>

                <Grid item xs={2}>
                  <InputNumberGtZeroField
                    name={`${detalle}.cantidad`}
                    label="Cantidad"
                    disabled={disabled}
                    type={"int"}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputTextField
                    name={`${detalle}.observacion`}
                    label="Observación"
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {!disabled && (
            <Tooltip title="Quitar item" placement="right-start">
              <Icon color="action" onClick={() => fields.splice(index, 1)}>
                remove_circle
              </Icon>
            </Tooltip>
          )}
        </Fragment>
      );
    })}
  </Grid>
);

class Form extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      productos,
      clientes,
      isMisVentas,
      openDialog,
      idPrograma,
      inBackOffice,
      idVenta
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {idVenta ? "Ver venta" : "Nueva venta"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors && errors.map(e => <li>{e.message}</li>)}
                </ul>
              </Grid>
            </Grid>

            <Grid container spacing={16}>
              <Grid item xs={12} md={5}>
                <InputTextField
                  name="numeroFactura"
                  label="N° de factura"
                  disabled={disabled}
                  required
                />
              </Grid>

              <Grid item xs={8} md={5}>
                {clientes && (
                  <ReduxTypeAhead
                    name="idCliente"
                    label="Cliente"
                    suggestions={{ sug: clientes }}
                    path={{ path: "sug", value: "value", label: "label" }}
                    placeholder="Cliente"
                    validate={[]}
                    disabled={disabled}
                  />
                )}
              </Grid>

              {!disabled && (
                <Grid item xs={2}>
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() => openDialog()}
                  >
                    Nuevo cliente
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="h6">Detalles de la venta</Typography>
              </Grid>

              <Grid item xs={12}>
                <FieldArray
                  name="fields"
                  component={renderDetallesVenta}
                  productos={productos}
                  disabled={disabled}
                />
              </Grid>

              <Grid
                className={classes.gridButton}
                item
                xs={12}
                style={{ marginBottom: "210px" }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  style={{ marginRight: 20 }}
                  onClick={() =>
                    history.push(
                      inBackOffice
                        ? `/admin/programa/${idPrograma}/ventas-retail`
                        : isMisVentas
                          ? `/mis-ventas`
                          : `/ventas`
                    )
                  }
                >
                  {idVenta ? "Volver" : "Cancelar"}
                </Button>
                {(!idVenta || idVenta === "new") && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={pristine || submitting || disabled}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formVentaRetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

Form = connect(
  state => ({
    formMeta: getFormMeta("formVentaRetail")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formVentaRetail", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
