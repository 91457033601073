import { getEnv } from "js/utils/Helper";

export const url = `${window.location.hostname.replace("www.", "")}`;
export const apiURL = `${window.location.protocol}//api.${url}:443/`;

export const graphqlURL = `${apiURL}graphql`;
export const exportURL = `${apiURL}export`;
export const kibanaUrl = `${apiURL}kibana`;
export const s3Url = `https://${getEnv}.liza.ashiwea.com/files/Templates/CSV/`;
export const logAccion = `${apiURL}log/accion`;
export const addImageURL = `${apiURL}S3/addImage`;
export const ssoURL = `${apiURL}sso/`;

export const tinymceKey = "yfzipi8m1ztkb8wvtpb7o2b80nxdzf0e27e3kd6hq8qglmuf";

export default {
  graphqlURL,
  exportURL,
  s3Url,
  tinymceKey,
  addImageURL
};

export const ESTADO_CANJE = [
  {
    value: "PENDIENTE",
    label: "Pendiente"
  },
  {
    value: "LOTEADO",
    label: "Loteado"
  },
  {
    value: "EN_COMPRA",
    label: "En compra"
  },
  {
    value: "EN_DISTRIBUCION",
    label: "En distribución"
  },
  {
    value: "ENTREGADO",
    label: "Entregado"
  },
  {
    value: "EN_PAUSA",
    label: "En pausa"
  },
  {
    value: "CANCELADO",
    label: "Cancelado"
  }
].sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

export const ADMINISTRACION = [
  {
    value: "ASHIWEA",
    label: "Ashiwea"
  },
  {
    value: "CLIENTE",
    label: "Programa"
  }
];

export const TIPO_PRODUCTO = [
  {
    value: "FISICO",
    label: "Físico"
  },
  {
    value: "INSTANTANEO",
    label: "Instántaneo"
  },
  {
    value: "CERTIFICADO",
    label: "Certificado"
  },
  {
    value: "ORDEN_COMPRA",
    label: "Orden de Compra"
  }
];

export const OPERACION = [
  {
    value: "DEBITO",
    label: "Débito"
  },
  {
    value: "CREDITO",
    label: "Crédito"
  }
];

export const TIPO_OPERACION = [
  {
    value: "CANJE",
    label: "Canje"
  },
  {
    value: "MOVIMIENTO",
    label: "Movimiento"
  },
  {
    value: "MONETARIO",
    label: "Monetario"
  },
  {
    value: "VENCIMIENTO",
    label: "Vencimiento"
  },
  {
    value: "NOTIFICACION",
    label: "Notificación"
  },
  {
    value: "OBJETIVO",
    label: "Objetivo"
  },
  {
    value: "VENCIMIENTO_PUNTOS",
    label: "Vencimiento puntos"
  },
  {
    value: "ACCION",
    label: "Acción"
  },
  {
    value: "CANCELACION",
    label: "Cancelación"
  },
  {
    value: "VENTA_RETAIL",
    label: "Venta retail"
  },
  {
    value: "VENTA_PRODUCTO_CODIFICADO",
    label: "Venta producto codificado"
  },
  {
    value: "CANJE_PUNTOS_CARGA_CODIGOS",
    label: "Canjeo de puntos por Código"
  }
];

export const TIPO_LOG = [
  {
    value: "CRON ACTIVACION ACCION",
    label: "Cron activar acción"
  },
  {
    value: "CRON FINALIZACION ACCION",
    label: "Cron finalizar acción"
  },
  {
    value: "CRON RECORDAR ACCION",
    label: "Cron recordar acción"
  },
  {
    value: "CRON RESULTADO ACCION",
    label: "Cron resultado acción"
  },
  {
    value: "CRON RECORDAR RECONOCIMIENTO",
    label: "Cron recordar reconocimiento"
  },
  {
    value: "CRON USUARIOS CLN",
    label: "Cron de usuarios de CLN"
  },
  {
    value: "CRON USUARIOS EDENOR",
    label: "Cron de usuarios de Edenor"
  },
  {
    value: "CRON ANIVERSARIO",
    label: "Cron aniversario"
  },
  {
    value: "CRON CUMPLEAÑOS",
    label: "Cron cumpleaños"
  },
  {
    value: "CRON AVISO VENCIMIENTO PUNTOS",
    label: "Cron aviso vencimiento puntos"
  },
  {
    value: "CRON VENCIMIENTO PUNTOS",
    label: "Cron vencimiento puntos"
  },
  {
    value: "CATEGORIAS",
    label: "Imporación de categorias"
  },
  {
    value: "USUARIOS",
    label: "Imporación de usuarios"
  },
  {
    value: "PRODUCTOS",
    label: "Imporación de productos"
  },
  {
    value: "PROVEEDORES",
    label: "Imporación de proveedores"
  },
  {
    value: "SUCURSALES",
    label: "Imporación de sucursales"
  },
  {
    value: "UBICACIONES",
    label: "Importación de ubicaciones"
  },
  {
    value: "CRON TASA REDENCION",
    label: "Cron de tasa de redencion"
  },
  {
    value: "CRON MOVIMIENTOS CERO",
    label: "Cron de movimientos en cero"
  },
  {
    value: "PUNTOSAUSUARIO",
    label: "Importación de puntos"
  },
  {
    value: "IMPORTAR BENEFICIOS",
    label: "Importación de beneficos"
  },
  {
    value: "IMPORTAR BENEFICIOS CATEGORÍAS",
    label: "Importación de categorías de beneficios"
  },
  {
    value: "IMPORTAR BENEFICIOS SEGMENTOS",
    label: "Importación de segmentos de beneficios"
  },
  {
    value: "IMPORTAR BENEFICIOS UBICACIONES",
    label: "Importación de ubicaciones de beneficios"
  },
  {
    value: "IMPORTAR SUCURSALES DE BENEFICIO",
    label: "Importación de sucursales de beneficios"
  },
  {
    value: "UPLOAD REPORTE COMERCIOS",
    label: "Importación reporte de comercios (CLN)"
  },
  {
    value: "UPLOAD REPORTE SUCURSALES",
    label: "Importación reporte de sucursales (CLN)"
  },
  {
    value: "CODIGOSACTIVACION",
    label: "Importación de códigos de activación"
  },
  {
    value: "CATALOGO_CATEGORIA",
    label: "Importación de categorías de catálogo"
  },
  {
    value: "CATALOGO_SEGMENTO",
    label: "Importación de segmentos de catálogo"
  },
  {
    value: "CATALOGO_UBICACION",
    label: "Importación de ubicaciones de catálogo"
  },
  {
    value: "JERARQUIA",
    label: "Importación de jerarquía"
  },
  {
    value: "LOCALIDADES",
    label: "Importación de localidades"
  },
  {
    value: "UPLOAD TARGETS OBJETIVOS Y PERFORMANCE",
    label: "Importación de targets de Unilever"
  },
  {
    value: "PRODUCTOS",
    label: "Importación de productos"
  },
  {
    value: "IMPORTAR CODIGOS DE BARRAS DE PRODUCTOS CODIFICADOS",
    label: "Importación de códigos de barras (Prod. codificados)"
  },
  {
    value: "IMPORTAR CODIGOS QR DE PRODUCTOS CODIFICADOS",
    label: "Importación de códigos QR (Prod. codificados)"
  },
  {
    value: "IMPORTAR CODIGOS SIN PRODUCTOS ASOCIADOS",
    label: "Importación de códigos sin productos (Prod. codificados)"
  },
  {
    value: "IMPORTAR PRODUCTOS CODIFICADOS",
    label: "Importación de productos codificados"
  },
  {
    value: "PROVEEDORES",
    label: "Importación de proveedores"
  },
  {
    value: "CATEGORIAS",
    label: "Importación de categorías"
  },
  {
    value: "SUCURSALES",
    label: "Importación de sucursales de proveedores"
  },
  {
    value: "UBICACIONES",
    label: "Importación de ubicaciones"
  },
  {
    value: "IMPORTAR CANALES RETAIL",
    label: "Importación de canales retail"
  },
  {
    value: "IMPORTAR STOCK DE CANALES RETAIL",
    label: "Importación de stock de canales retail"
  },
  {
    value: "IMPORTAR CLIENTES RETAIL",
    label: "Importación de clientes retail"
  },
  {
    value: "IMPORTAR PRODUCTOS RETAIL",
    label: "Importación de productos retail"
  },
  {
    value: "IMPORTAR VENDEDORES DE CANALES RETAIL",
    label: "Importación de vendedores de canales retail"
  },
  {
    value: "CRON VENCIMIENTO BENEFICIOS",
    label: "Cron vencimiento de beneficios"
  },
  {
    value: "CRON VENTAS UNILEVER",
    label: "Cron ventas Unilever"
  },
  {
    value: "CRON FINALIZACION CAMPANA OP",
    label: "Cron finalizar campañas de Unilever"
  },
  {
    value: "CRON AVISO CUMPLEAÑOS A JEFE Y COMPAÑEROS",
    label: "Cron aviso cumpleaños a jefe y compañeros"
  },
  {
    value: "CRON ASIGNACION PUNTOS POR CAMPAÑAS",
    label: "Cron asignación puntos por campañas"
  },
  {
    value: "CRON CALCULO DE PERFORMANCE ALIADOS MERCADO PAGO",
    label: "Cron calculo de performance Aliados Meracado Pago"
  },
  {
    value: "CRON CALCULO TARGETS UNILEVER",
    label: "Cron calculo de targets Unilever"
  },
  {
    value: "CRON INICIALIZAR CAMPANAS PENDIENTES",
    label: "Cron incializar campañas pendientes"
  },
  {
    value: "CRON INICIALIZAR CAMPANAS PENDIENTES UNILEVER",
    label: "Cron incializar campañas pendientes Unilever"
  },
  {
    value: "CRON RECORDAR SUPERVISORES Y EDD DE UNILEVER",
    label: "Cron recordar supervisores y EDD de Unilever"
  },
  {
    value: "IMPORTAR MEDs DE UNILEVER",
    label: "Importación MEDs de Unilever"
  },
  {
    value: "IMPORTAR PREVENTISAS MED DE UNILEVER",
    label: "Importación preventistas de MED de Unilever"
  },
  {
    value: "IMPORTAR VARIABLES DE MEDs DE UNILEVER",
    label: "Importación variables de MEDs de Unilever"
  },
  {
    value: "UPLOAD CAMPAÑAS",
    label: "Importación campañas"
  },
  {
    value: "UPLOAD OBJETIVOS CAMPAÑAS",
    label: "Importación objetivos de campañas"
  },
  {
    value: "UPLOAD TARGETS CAMPAÑAS",
    label: "Importación targets de campañas"
  },
  {
    value: "CRON EXPORTACION USUARIOS",
    label: "Cron exportación usuarios"
  }
  // {
  //   value: "CRON VENCIMIENTO DE USUARIOS CON PERMISOS SENSIBLES",
  //   label: "Cron vencimiento de usuarios con permisos sensibles"
  // },
].sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

export const MIS_TIPOS_LOGS = [
  {
    value: "USUARIOS",
    label: "Imporación de usuarios"
  },
  {
    value: "PUNTOSAUSUARIO",
    label: "importación de puntos"
  }
].sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

//Se incorpora array de regexs para validad el campo Nro. de Factura en el caso del cliente Quimfa se deja el valor en duro
//Ya que desde el lado del modulo de Venta de Facturas no posee parametrización

export const regexs = [
  {
    idPrograma: 117,
    regex: /^[0-9]{13}$/
  }
];
