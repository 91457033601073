export const USER_ROLES = {
  ACCESO_BO: "ACCESO_BO",
  ACCION_R: "ACCION_R",
  ACCION_W: "ACCION_W",
  ASIGNAR_PUNTOS_PROGRAMA_W: "ASIGNAR_PUNTOS_PROGRAMA_W",
  ASIGNAR_PUNTOS_USUARIO_W: "ASIGNAR_PUNTOS_USUARIO_W",
  IMPORT_ASIGNAR_PUNTOS_USUARIO_W: "IMPORT_ASIGNAR_PUNTOS_USUARIO_W",
  ASIGNAR_ROL_USUARIO: "ASIGNAR_ROL_USUARIO",
  CANCELAR_CANJE: "CANCELAR_CANJE",
  CANJE_R: "CANJE_R",
  CANJE_W: "CANJE_W",
  CANJE_JERARQUIA_R: "CANJE_JERARQUIA_R",
  CANJE_TERCEROS_W: "CANJE_TERCEROS_W",
  CANJEAR_PRODUCTO_W: "CANJEAR_PRODUCTO_W",
  CATALOGO_R: "CATALOGO_R",
  CATEGORIA_R: "CATEGORIA_R",
  CATEGORIA_W: "CATEGORIA_W",
  CC_SELECT_GERENCIA: "CC_SELECT_GERENCIA",
  CLN_REPORTE_R: "CLN_REPORTE_R",
  CLN_MI_REPORTE_R: "CLN_MI_REPORTE_R",
  CLN_REPORTE_W: "CLN_REPORTE_W",
  CODIGO_INSTANTANEO_R: "CODIGO_INSTANTANEO_R",
  CODIGO_INSTANTANEO_W: "CODIGO_INSTANTANEO_W",
  CONTENIDOS_R: "CONTENIDOS_R",
  CREAR_RECONOCIMIENTO_W: "CREAR_RECONOCIMIENTO_W",
  APROBAR_RECONOCIMIENTO_W: "APROBAR_RECONOCIMIENTO_W",
  DEFINICION_PERFIL_R: "DEFINICION_PERFIL_R",
  DEFINICION_PERFIL_W: "DEFINICION_PERFIL_W",
  EXCEDER_MAXIMO: "EXCEDER_MAXIMO",
  FACTURA_R: "FACTURA_R",
  FACTURA_W: "FACTURA_W",
  FAVORITO_R: "FAVORITO_R",
  FAVORITO_W: "FAVORITO_W",
  FORMULARIO_RECONOCIMIENTO_R: "FORMULARIO_RECONOCIMIENTO_R",
  HISTORIAL_EMAIL_R: "HISTORIAL_EMAIL_R",
  HISTORIAL_EMAIL_W: "HISTORIAL_EMAIL_W",
  IDIOMA_R: "IDIOMA_R",
  IDIOMA_W: "IDIOMA_W",
  LANDING_W: "LANDING_W",
  LAYOUT_W: "LAYOUT_W",
  LEGALES_W: "LEGALES_W",
  LOG_R: "LOG_R",
  LOGIN_AS: "LOGIN_AS",
  LOTE_R: "LOTE_R",
  LOTE_W: "LOTE_W",
  MI_PERFIL_W: "MI_PERFIL_W",
  MIS_DATOS_R: "MIS_DATOS_R",
  MIS_DATOS_MOVIMIENTOS_R: "MIS_DATOS_MOVIMIENTOS_R",
  MOVIMIENTO_PROGRAMA_R: "MOVIMIENTO_PROGRAMA_R",
  MOVIMIENTO_USUARIO_R: "MOVIMIENTO_USUARIO_R",
  EXPORT_MOVIMIENTO_USUARIO_R: "EXPORT_MOVIMIENTO_USUARIO_R",
  PERFIL_W: "PERFIL_W",
  PERMISO_R: "PERMISO_R",
  PRODUCTO_R: "PRODUCTO_R",
  PRODUCTO_W: "PRODUCTO_W",
  PRODUCTO_CATALOGO_R: "PRODUCTO_CATALOGO_R",
  PRODUCTO_CATALOGO_W: "PRODUCTO_CATALOGO_W",
  PROGRAMA_LISTA: "PROGRAMA_LISTA",
  PROGRAMA_R: "PROGRAMA_R",
  PROGRAMA_W: "PROGRAMA_W",
  PROVEEDOR_R: "PROVEEDOR_R",
  PROVEEDOR_W: "PROVEEDOR_W",
  REPORTE_R: "REPORTE_R",
  REPORTE_NAVEGACION: "REPORTE_NAVEGACION",
  REPORTE_MOVIMIENTOS_PROGRAMA: "REPORTE_MOVIMIENTOS_PROGRAMA",
  REPORTE_MOVIMIENTOS_USUARIO: "REPORTE_MOVIMIENTOS_USUARIO",
  REPORTE_CANJES: "REPORTE_CANJES",
  REPORTE_BALANCE: "REPORTE_BALANCE",
  REPORTE_RANKING_PRODUCTOS: "REPORTE_RANKING_PRODUCTOS",
  REPORTE_INDICADORES: "REPORTE_INDICADORES",
  ROL_R: "ROL_R",
  ROL_W: "ROL_W",
  TEMPLATE_W: "TEMPLATE_W",
  UBICACION_R: "UBICACION_R",
  UBICACION_W: "UBICACION_W",
  USUARIO_R: "USUARIO_R",
  USUARIO_W: "USUARIO_W",
  CREAR_USUARIO_W: "CREAR_USUARIO_W",
  BLANQUEO_PASSWORD_W: "BLANQUEO_PASSWORD_W",
  IMPORTAR_USUARIO_W: "IMPORTAR_USUARIO_W",
  VER_RECONOCIMIENTOS_APROBADOS_R: "VER_RECONOCIMIENTOS_APROBADOS_R",
  VER_RECONOCIMIENTOS_R: "VER_RECONOCIMIENTOS_R",
  CAMPANA_OP_R: "CAMPANA_OP_R",
  CAMPANA_OP_W: "CAMPANA_OP_W",
  GRUPO_SKU_W: "GRUPO_SKU_W",
  TARGET_USUARIO_W: "TARGET_USUARIO_W",
  TARGET_USUARIO_R: "TARGET_USUARIO_R",
  INFORME_PERFORMANCE_R: "INFORME_PERFORMANCE_R",
  MI_INFORME_PERFORMANCE_R: "MI_INFORME_PERFORMANCE_R",
  JERARQUIA_R: "JERARQUIA_R",
  JERARQUIA_W: "JERARQUIA_W",
  PRODUCTO_RETAIL_R: "PRODUCTO_RETAIL_R",
  PRODUCTO_RETAIL_W: "PRODUCTO_RETAIL_W",
  PRODUCTO_RETAIL_IMPORT: "PRODUCTO_RETAIL_IMPORT",
  PRODUCTO_RETAIL_EXPORT: "PRODUCTO_RETAIL_EXPORT",
  CANAL_RETAIL_R: "CANAL_RETAIL_R",
  CANAL_RETAIL_W: "CANAL_RETAIL_W",
  CANAL_RETAIL_IMPORT: "CANAL_RETAIL_IMPORT",
  CANAL_RETAIL_EXPORT: "CANAL_RETAIL_EXPORT",
  VENTA_RETAIL_R: "VENTA_RETAIL_R",
  VENTA_RETAIL_W: "VENTA_RETAIL_W",
  VENTA_RETAIL_EXPORT: "VENTA_RETAIL_EXPORT",
  MIS_VENTA_RETAIL: "MIS_VENTA_RETAIL",
  CLIENTE_RETAIL_R: "CLIENTE_RETAIL_R",
  CLIENTE_RETAIL_W: "CLIENTE_RETAIL_W",
  CLIENTE_RETAIL_IMPORT: "CLIENTE_RETAIL_IMPORT",
  CLIENTE_RETAIL_EXPORT: "CLIENTE_RETAIL_EXPORT",
  PRODUCTO_CODIFICADO_R: "PRODUCTO_CODIFICADO_R",
  PRODUCTO_CODIFICADO_W: "PRODUCTO_CODIFICADO_W",
  PRODUCTO_CODIFICADO_IMPORT: "PRODUCTO_CODIFICADO_IMPORT",
  PRODUCTO_CODIFICADO_EXPORT: "PRODUCTO_CODIFICADO_EXPORT",
  VENTA_PRODUCTO_CODIFICADO_R: "VENTA_PRODUCTO_CODIFICADO_R",
  VENTA_PRODUCTO_CODIFICADO_W: "VENTA_PRODUCTO_CODIFICADO_W",
  VENTA_PRODUCTO_CODIFICADO_EXPORT: "VENTA_PRODUCTO_CODIFICADO_EXPORT",
  MIS_VENTA_PRODUCTO_CODIFICADO: "MIS_VENTA_PRODUCTO_CODIFICADO",
  CODIGO_BARRA_R: "CODIGO_BARRA_R",
  CODIGO_QR_R: "CODIGO_QR_R",
  BENEFICIO_R: "BENEFICIO_R",
  BENEFICIO_W: "BENEFICIO_W",
  SUCURSAL_BENEFICIO_R: "SUCURSAL_BENEFICIO_R",
  SUCURSAL_BENEFICIO_W: "SUCURSAL_BENEFICIO_W",
  CATALOGO_BENEFICIO_R: "CATALOGO_BENEFICIO_R",
  SOLICITUD_BENEFICIO_W: "SOLICITUD_BENEFICIO_W",
  SOLICITUD_BENEFICIO_R: "SOLICITUD_BENEFICIO_R",
  MIS_SOLICITUDES_BENEFICIO_R: "MIS_SOLICITUDES_BENEFICIO_R",
  FAVORITO_BENEFICIO_W: "FAVORITO_BENEFICIO_W",
  FAVORITO_BENEFICIO_R: "FAVORITO_BENEFICIO_R",
  MED_R: "MED_R",
  MIS_MEDS_R: "MIS_MEDS_R",
  MED_W: "MED_W",
  CAMPANA_R: "CAMPANA_R",
  CAMPANA_W: "CAMPANA_W",
  CAMPANA_PARAMETROS_R: "CAMPANA_PARAMETROS_R",
  CAMPANA_PARAMETROS_W: "CAMPANA_PARAMETROS_W",
  CAMPANA_TARGET_R: "CAMPANA_TARGET_R",
  CAMPANA_TARGET_W: "CAMPANA_TARGET_W",
  CAMPANA_PRODUCTOS_R: "CAMPANA_PRODUCTOS_R",
  CAMPANA_PRODUCTOS_W: "CAMPANA_PRODUCTOS_W",
  CAMPANA_PERFORMANCE_R: "CAMPANA_PERFORMANCE_R",
  CAMPANA_MI_PERFORMANCE_R: "CAMPANA_MI_PERFORMANCE_R",
  CARGACODIGOS_R: "CARGACODIGOS_R",
  CARGACODIGOS_W: "CARGACODIGOS_W",
  CARGA_CODIGOS_IMPORT: "CARGA_CODIGOS_IMPORT",
  CARGA_CODIGOS_EXPORT: "CARGA_CODIGOS_EXPORT",
  VENTA_CARGA_CODIGOS_W: "VENTA_CARGA_CODIGOS_W",
  VENTA_CARGA_CODIGOS_R: "VENTA_CARGA_CODIGOS_R",
  CATALOGO_CODIGO_W: "CATALOGO_CODIGO_W",
  CATALOGO_CODIGO_R: "CATALOGO_CODIGO_R",
  SOLICITUD_CATALOGO_CODIGO_R: "SOLICITUD_CATALOGO_CODIGO_R",
  SOLICITUD_CATALOGO_CODIGO_W: "SOLICITUD_CATALOGO_CODIGO_W",
  SUCURSAL_CATALOGO_CODIGO_R: "SUCURSAL_CATALOGO_CODIGO_R",
  SUCURSAL_CATALOGO_CODIGO_W: "SUCURSAL_CATALOGO_CODIGO_W",
  CATALOGO_CATALOGO_CODIGO_R: "CATALOGO_CATALOGO_CODIGO_R",
  MIS_SOLICITUDES_CATALOGO_CODIGO_R: "MIS_SOLICITUDES_CATALOGO_CODIGO_R",
  MODERACION_W: "MODERACION_W",
  MODERACION_R: "MODERACION_R",
  MODERACION_IMPORT: "MODERACION_IMPORT",
  MODERACION_EXPORT: "MODERACION_EXPORT",
  MODERACION_FACTURA_POR_PUNTOS_W: "MODERACION_FACTURA_POR_PUNTOS_W",
  MODERACION_FACTURA_POR_PUNTOS_R: "MODERACION_FACTURA_POR_PUNTOS_R",
  MODERACION_FACTURA_POR_PUNTOS_IMPORT: "MODERACION_FACTURA_POR_PUNTOS_IMPORT",
  MODERACION_FACTURA_POR_PUNTOS_EXPORT: "MODERACION_FACTURA_POR_PUNTOS_EXPORT",
  FACTURAS_R: "FACTURAS_R",
  FACTURAS_W: "FACTURAS_W",
  FACTURAS_POR_PUNTOS_R: "FACTURAS_POR_PUNTOS_R",
  FACTURAS_POR_PUNTOS_W: "FACTURAS_POR_PUNTOS_W",
  FACTURAS_POR_PUNTOS_CT: "FACTURAS_POR_PUNTOS_CT",
  LINKS_TRACKING_R: "LINKS_TRACKING_R",
  LINKS_TRACKING_W: "LINKS_TRACKING_W",
  SESSION_TRACKING_R: "SESSION_TRACKING_R",
  SESSION_TRACKING_W: "SESSION_TRACKING_W",
  PREVENTISTA_PORCENTAJE_R: "PREVENTISTA_PORCENTAJE_R",
  PREVENTISTA_PORCENTAJE_W: "PREVENTISTA_PORCENTAJE_W",
  API_TOKEN_R: "API_TOKEN_R",
  API_TOKEN_W: "API_TOKEN_W",
  PARAMETROS_CARGA_FACTURA_R: "PARAMETROS_CARGA_FACTURA_R",
  PARAMETROS_CARGA_FACTURA_W: "PARAMETROS_CARGA_FACTURA_W",
  PUNTO_VENTA_R: "PUNTO_VENTA_R",
  PUNTO_VENTA_W: "PUNTO_VENTA_W"
};
