import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { CuentaCorriente } = Fragmentos;

export const getCuentaCorriente = gql`
  query getCuentaCorriente($page: Int!, $rowsPerPage: Int!) {
    getCuentaCorriente(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
    ) {
      ...cuentaCorrienteUsuario
    }
  }
  ${CuentaCorriente.fragments.cuentaCorrienteUsuario}
`;

export const getMovimientos = gql`
  query getMovimientos(
    $idPrograma: Int
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: MovimientoColumn!
    $operacion: Operacion
    $tipoOperacion: [TipoOperacion]
    $numeroFactura: String
    $cuentaCorriente_tipo: CuentaCorrienteTipo
    $concepto: String
    $monedaBase: String
    $solicitante_username: String
    $min: Date
    $max: Date
  ) {
    getMovimientos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      movimientoOrder: { column: $orderBy, order: $order }
      movimientoLike: {
        cuentaCorriente_programa_idPrograma: $idPrograma
        operacion: $operacion
        tipoOperacion: $tipoOperacion
        numeroFactura: $numeroFactura
        cuentaCorriente_tipo: $cuentaCorriente_tipo
        concepto: $concepto
        monedaBase: $monedaBase
        solicitante_username: $solicitante_username
      }
      movimientoRange: { fecha: { min: $min, max: $max } }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idMovimiento
        idCuentaCorriente
        balance
        tipoOperacion
        operacion
        fecha
        numeroFactura
        concepto
        monto
        monedaBase
        puntos
        cuentaCorriente {
          programa {
            idPrograma
            nombre
          }
        }
        solicitante {
          username
        }
      }
    }
  }
`;

export const setearPuntosAPrograma = gql`
  mutation setearPuntosAPrograma(
    $idPrograma: Int!
    $numeroFactura: String!
    $concepto: String!
    $puntosAsignados: Int!
    $tipo: Operacion!
  ) {
    setearPuntosAPrograma(
      puntos: {
        idPrograma: $idPrograma
        numeroFactura: $numeroFactura
        concepto: $concepto
        puntosAsignados: $puntosAsignados
      }
      operacion: $tipo
    ) {
      balance
    }
  }
`;

export const CCMovimientoDeProgramaAUsuario = gql`
  mutation CCMovimientoDeProgramaAUsuario(
    $puntos: PuntosUsuarioCreate!
    $operacion: Operacion!
  ) {
    setearPuntosAUsuario(puntos: $puntos, operacion: $operacion) {
      idCuentaCorriente
      balance
      tipo
    }
  }
`;

export default {
  queries: {
    getCuentaCorriente,
    getMovimientos,
    setearPuntosAPrograma,
    CCMovimientoDeProgramaAUsuario
  }
};
