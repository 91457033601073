import React, { Component, Fragment } from "react";
import { ApolloProvider } from "react-apollo";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import AppQraphQLForLink from "js/graphql/resolvers/linksTracking.resolver";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { TemplateContext } from "js/template-context";
import { NotificationProvider } from "js/notification-context";
import AuthService from "js/utils/AuthService";
import {
  getSelectedFonts,
  getProgramaPublicInfo,
  setIdPrograma,
  getPrograma,
  getTheme,
  setTabBrowser,
  setWebFonts,
  setPrivacy,
  setHtmlExpectativa,
  initGoogleAnalytics,
  initGoogleUniversalAnalytics,
  initManifest,
  setPaisesPrograma
} from "./utils.js";
import TranslationService from "js/utils/TranslationService";
import Routes from "./App.routes.js";
import client from "./client.graphql.js";
import { get, isEmpty } from "lodash";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import PermService from "js/utils/PermService";
import TrackEventService from "js/utils/TrackEventService.js";

class App extends Component {
  state = { loading: true };
  async componentDidMount() {
    const url = window.location.hostname.replace("www.", "");
    const urlParams = new URLSearchParams(window.location.search);
    const pathname = window.location.pathname;
    const token = urlParams.get("token");
    AuthService.init();
    if (token) {
      // loginAs code via token
      if (!token) return;
      if (pathname.indexOf("email-verificado") === -1) {
        const res = await client.mutate({
          mutation: AppQraphQL.mutations.loginAs,
          variables: { token },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
          refetchQueries: [`getRedirect`]
        });
        if (!isEmpty(res.data.loginAs)) {
          PermService.init();
          AuthService.setIdUsuario(res.data.loginAs.usuario.idUsuario);
          const privilegios = res.data.loginAs.privilegios.map(obj => {
            return obj.codigoPrivilegio;
          });
          AuthService.setRoles(privilegios);
        }
      }
    }

    const {
      data: { isLoggedIn }
    } = await client.query({
      query: AppQraphQL.queries.isLoggedIn,
      variables: { url },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });

    this.props.setLogInStatus(isLoggedIn);
    if (!isLoggedIn) AuthService.clearStorage();

    const queryOption = isLoggedIn
      ? AppQraphQL.queries.getPrograma
      : AppQraphQL.queries.getProgramaPublicInfo;

    await client
      .query({
        query: queryOption,
        variables: { url },
        errorPolicy: "all",
        fetchPolicy: "cache-first"
      })
      .then(({ data }) => {
        let programa = undefined;
        setWebFonts();

        let theme = getTheme();

        if (data) {
          programa = isLoggedIn
            ? getPrograma(data)
            : getProgramaPublicInfo(data);

          const currentProgram = isLoggedIn
            ? data.getPrograma
            : data.getProgramaPublicInfo;
          const {
            nombre,
            esPublico,
            htmlExpectativa,
            idiomas,
            segmentoDefault: { idiomaDefault },
            codigoChatOnline,
            chatSinLogin
          } = currentProgram;

          this.props.setChatOnline({ codigoChatOnline, chatSinLogin });
          const { templateData } =
            get(data, "miUsuario.segmento.template") || programa.template;
          setPrivacy(esPublico);
          setHtmlExpectativa(htmlExpectativa);
          setIdPrograma(
            parseInt(currentProgram.idPrograma) || currentProgram.idPrograma
          );
          setPaisesPrograma(
            currentProgram.paises
              ? currentProgram.paises
              : currentProgram.settings && currentProgram.settings.paises
                ? currentProgram.settings.paises
                : []
          );
          PermService.init();
          let miIdioma = get(data, "miUsuario.idioma");
          let idiomaDeUsuario =
            miIdioma &&
            idiomas.some(({ idioma: { _id } }) => _id === miIdioma._id)
              ? miIdioma
              : undefined;
          TranslationService.initIdioma(idiomaDefault, idiomaDeUsuario);
          setTabBrowser({
            favicon: templateData.favicon,
            nombre
          });
          initGoogleAnalytics(templateData.GA);
          initManifest(
            nombre,
            get(data, "miUsuario.segmento.template") || programa.template
          );

          initGoogleUniversalAnalytics(templateData.GA);
          initManifest(
            nombre,
            get(data, "miUsuario.segmento.template") || programa.template
          );

          const selectedFonts = getSelectedFonts(programa, templateData);
          setWebFonts(selectedFonts);
          theme = getTheme(templateData.theme);

          if (programa && programa.modulos) {
            programa.modulos.forEach(modulo => {
              if (
                modulo.nombreGrupo.toLowerCase() === "tracking de links" &&
                modulo.estado === "ACTIVO"
              ) {
                AuthService.setTrackLinks(true);
                AuthService.setLinksToTrack(programa.linksToTrack);

                // si es BO no se trackean.
                window.location.pathname.indexOf("admin") === -1 &&
                  client
                    .query({
                      query: AppQraphQLForLink.queries.getLinksToTrack,
                      fetchPolicy: "network-only",
                      variables: {
                        idPrograma: parseInt(programa.idPrograma)
                      }
                    })
                    .then(({ data }) => {
                      AuthService.setTrackearTodosLinks(
                        data.getLinksToTrack.trackearTodos
                      );
                      if (data.getLinksToTrack.trackearTodos) {
                        AuthService.setUltimaURLToTrack(window.location.href);
                        document.addEventListener("click", function(e) {
                          setTimeout(() => {
                            if (
                              AuthService.getUltimaURLToTrack() !==
                              window.location.href
                            ) {
                              AuthService.setUltimaURLToTrack(
                                window.location.href
                              );

                              client
                                .mutate({
                                  mutation:
                                    AppQraphQLForLink.mutations.trackLink,
                                  variables: {
                                    link: AuthService.getUltimaURLToTrack(),
                                    idPrograma: parseInt(programa.idPrograma),
                                    origen: "trackTodos"
                                  },
                                  errorPolicy: "all",
                                  fetchPolicy: "no-cache"
                                })
                                .then(res => {})
                                .catch(e =>
                                  console.log("Error al trackear link")
                                );
                            }
                          }, 200);
                        });
                      }
                    })
                    .catch(e => console.log);
              }
              if (
                modulo.nombreGrupo.toLowerCase() === "tracking de sesiones" &&
                modulo.estado === "ACTIVO"
              ) {
                AuthService.setTrackSession(true);
              }
            });
          }
        }

        this.props.addTemplate({
          theme,
          programa
        });
      });
    await client.queryManager.refetchQueryByName("getRedirect");
    this.setState({ loading: false });
  }
  render() {
    const trackSession =
      window.location.pathname.indexOf("admin") === -1 &&
      AuthService.getTrackSession();
    const { isAllowedToNavigate } = this.props;
    const { theme, programa } = this.props.template;
    const { loading } = this.state;
    if (!theme || loading) return false;

    return (
      <Fragment>
        <ApolloProvider client={client}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <TemplateContext.Provider value={programa}>
              <NotificationProvider>
                <MuiThemeProvider theme={theme}>
                  {trackSession && <TrackEventService />}

                  <Routes
                    programa={programa}
                    isAllowedToNavigate={isAllowedToNavigate}
                  />
                </MuiThemeProvider>
              </NotificationProvider>
            </TemplateContext.Provider>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </Fragment>
    );
  }
}

export default App;
