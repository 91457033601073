import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./css/normalize.css";
import "./css/dropzone.css";

import App from "js/App/App.container";
import { Provider } from "react-redux";
import crearStore from "js/store";

const url = window.location.hostname.replace("www.", "");

ReactDOM.render(
  <Provider store={crearStore(url)}>
    <App />
  </Provider>,
  document.getElementById("root")
);
