import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import { connect } from "react-redux";
import { reduxForm, change, getFormMeta } from "redux-form";
import { Grid, Button } from "@material-ui/core";
import {
  InputTextField,
  InputNumberField
} from "js/components/Shared/Form/Fields";

class NewClienteDialog extends PureComponent {
  render() {
    const {
      submitting,
      pristine,
      open,
      handleClose,
      handleSubmit,
      errors,
      classes
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nuevo cliente</DialogTitle>
        <DialogContent>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <form className={classes.container} onSubmit={handleSubmit}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <ul className={classes.error}>
                      {errors && errors.map(e => <li>{e.message}</li>)}
                    </ul>
                  </Grid>
                </Grid>

                <Grid container spacing={16}>
                  <Grid item xs={12} md={6}>
                    <InputTextField name="nombre" label="Nombre" required />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputNumberField
                      name="documento"
                      label="Documento"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputTextField name="email" label="Email" />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputTextField name="telefono" label="Teléfono" />
                  </Grid>

                  <Grid className={classes.gridButton} item xs={12}>
                    <Button
                      type="button"
                      variant="outlined"
                      size="large"
                      color="primary"
                      className={classes.button}
                      style={{ marginRight: 20 }}
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.button}
                      disabled={pristine || submitting}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

NewClienteDialog = reduxForm({
  form: "formClienteRetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(NewClienteDialog);

NewClienteDialog = connect(
  state => ({
    formMeta: getFormMeta("formClienteRetail")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formClienteRetail", field, value))
  })
)(NewClienteDialog);

export default withStyles(styles)(NewClienteDialog);
