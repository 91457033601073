import { compose } from "recompose";
import { withNotificationContext } from "js/notification-context";
import DialogEstadoRaw from "./DialogEstado";
import DialogFechaRaw from "./DialogFecha";
import DialogFacturaRaw from "./DialogFactura";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.js";

const enhance = compose(withNotificationContext);

export const DialogEstado = enhance(withStyles(styles)(DialogEstadoRaw));
export const DialogFecha = enhance(DialogFechaRaw);
export const DialogFactura = enhance(withStyles(styles)(DialogFacturaRaw));

export default {
  DialogEstado,
  DialogFecha,
  DialogFactura
};
