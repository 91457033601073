import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Form from "js/pages/VentaRetail/Edit/MyForm.js";
import Dialog from "js/pages/VentaRetail/Edit/NewClienteDialog.js";
import AppQraphQL from "js/graphql/resolvers/ventaRetail.resolver";
import ProductosGraphQL from "js/graphql/resolvers/productoRetail.resolver";
import ClientesGraphQL from "js/graphql/resolvers/clienteRetail.resolver";
import { removeTypename } from "js/utils/Helper";
import { updateUserPoints } from "js/redux/user/user.actions";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    let id = null;
    let inBackOffice = false;
    let idVenta = this.props.match.params.idVenta;
    if (window.location.pathname.indexOf("admin") !== -1) {
      id = this.props.match.params.id;
      inBackOffice = true;
    } else {
      id = parseInt(window.localStorage.getItem("idPrograma"));
      inBackOffice = false;
    }
    if (!idVenta) {
      idVenta = this.props.computedMatch.params.idVenta;
    }

    this.state = {
      disabled: false,
      errors: null,
      dialogErrors: null,
      id,
      idVenta,
      clientes: [],
      productos: [],
      isMisVentas: window.location.pathname.indexOf("mis") !== -1,
      initialValues: {},
      open: false,
      inBackOffice
    };
  }

  openDialog() {
    this.setState({
      open: true
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  async submitDialog(values) {
    const { client } = this.props;
    const { id } = this.state;
    values.idPrograma = id;
    values.estado = "ACTIVO";
    console.log(values);
    await client
      .mutate({
        mutation: ClientesGraphQL.mutations.createClienteRetail,
        variables: {
          cliente: {
            ...values
          }
        }
      })
      .then(res => {
        const mensaje = "Cliente creado y agregado corrrectamente";
        const cliente = res.data.createClienteRetail;
        const { clientes, initialValues } = this.state;
        clientes.push({ value: cliente._id, label: cliente.nombre });
        this.setState({
          clientes,
          open: false,
          initialValues: {
            ...initialValues,
            idCliente: cliente._id
          }
        });
        this.props.openNotification(mensaje);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          dialogErrors:
            errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id, idVenta, isMisVentas } = this.state;
    const fieldsVariables = { idPrograma: id, estado: "ACTIVO" };

    if (idVenta !== "new") {
      delete fieldsVariables.estado;
      await client
        .query({
          query: isMisVentas
            ? AppQraphQL.queries.getMiVentaRetailById
            : AppQraphQL.queries.getVentaRetailById,
          fetchPolicy: "network-only",
          variables: {
            ventaLike: {
              _id: idVenta
            }
          }
        })
        .then(res => {
          const venta = isMisVentas
            ? res.data.getMisVentasRetail.itemsPagina[0]
            : res.data.getVentasRetail.itemsPagina[0];
          const cliente = venta.cliente;
          const detalles = venta.detalles;
          venta.idCliente = cliente ? cliente._id : null;
          venta.fields = detalles.map(detalle => {
            return {
              codigoProducto: detalle.producto.codigo,
              cantidad: detalle.cantidad,
              observacion: detalle.observacion
            };
          });
          this.setState({
            initialValues: {
              ...venta
            },
            disabled: true
          });
        });
    }

    await client
      .query({
        query: ProductosGraphQL.queries.getProductosRetailInputByidUsuario,
        fetchPolicy: "network-only",
        variables: {
          productoLike: {
            ...fieldsVariables
          }
        }
      })
      .then(res => {
        const productos = res.data.getProductosRetailByidUsuario
          ? res.data.getProductosRetailByidUsuario.itemsPagina
          : null;
        this.setState({ productos });
      });

    await client
      .query({
        query: ClientesGraphQL.queries.getClientesRetailInput,
        fetchPolicy: "network-only",
        variables: {
          clienteLike: {
            ...fieldsVariables
          }
        }
      })
      .then(res => {
        const clientes = res.data.getClientesRetail
          ? res.data.getClientesRetail.itemsPagina
          : null;
        this.setState({ clientes });
      });
  }

  async submit(values) {
    const { client, history, updateUserPoints } = this.props;
    const { id, isMisVentas, inBackOffice } = this.state;

    if (!values.fields) {
      this.props.openNotification("Debe agregar al menos un producto");
    } else {
      values.detalles = values.fields.map(field => {
        return {
          ...field,
          cantidad: parseInt(field.cantidad)
        };
      });
      let variables = {
        ...values,
        idPrograma: id,
        idUsuario: parseInt(window.localStorage.getItem("idUsuario"))
      };
      delete variables.fields;
      await client
        .mutate({
          mutation: AppQraphQL.mutations.createVentaRetail,
          variables: {
            venta: { ...removeTypename(variables) }
          }
        })
        .then(res => {
          const mensaje = "Venta creada corrrectamente";
          this.props.openNotification(mensaje);
          history.push(
            inBackOffice
              ? `/admin/programa/${id}/ventas-retail`
              : isMisVentas
                ? `/mis-ventas`
                : `/ventas`
          );
          updateUserPoints(true);
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Form
          errors={this.state.errors}
          disabled={this.state.disabled}
          onSubmit={this.submit.bind(this)}
          productos={this.state.productos}
          clientes={this.state.clientes}
          isMisVentas={this.state.isMisVentas}
          initialValues={this.state.initialValues}
          openDialog={this.openDialog.bind(this)}
          idPrograma={this.state.id}
          inBackOffice={this.state.inBackOffice}
          idVenta={this.state.idVenta}
        />
        <Dialog
          onSubmit={this.submitDialog.bind(this)}
          errors={this.state.dialogErrors}
          open={this.state.open}
          handleClose={this.handleClose.bind(this)}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateUserPoints: updatePoints => dispatch(updateUserPoints(updatePoints))
});

export default connect(
  null,
  mapDispatchToProps
)(Edit);
