import React, { Fragment, Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Select, TextField, RadioGroup } from "redux-form-material-ui";
import {
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Checkbox,
  Tooltip,
  InputAdornment
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";

import { styles } from "js/components/Shared/Form/styles.js";
import { required, email } from "js/components/Shared/Form/validations.js";
import ErrorList from "js/components/Shared/ErrorList";
import { isValidUrl, isValidUrlOrEmpty } from "js/helpers/validations";
import { connect } from "react-redux";
import {
  MonedaField,
  PaisMultipleField,
  InputNumberField,
  InputNumberGtZeroField,
  DatePicker
} from "js/components/Shared/Form/Fields";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaLanzamientoNotChanged: true,
      showChatInputCode: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fechaLanzamiento !== this.props.fechaLanzamiento) {
      this.setState({
        fechaLanzamiento: this.props.fechaLanzamiento
      });
    }

    if (
      prevProps.initialValues !== this.props.initialValues &&
      (this.props.initialValues.modulosSeleccionados &&
        this.props.initialValues.modulosSeleccionados["Chat Online"])
    ) {
      this.setState({ showChatInputCode: true });
    }
  }

  handleChangeFecha = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaLanzamientoNotChanged: false
    });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      grupos,
      id,
      avisoCumpleCompaneros,
      definicionesPerfil,
      cronExportacionUsuarios,
      esNotificacionStockBajo
    } = this.props;
    const indicadores = [
      "Tasa de redención",
      "Tiempo de entrega promedio",
      "Saldo puntos cuenta corriente programa",
      "Saldo puntos cuenta corriente usuarios",
      "Saldo $ cuenta corriente programa",
      "Saldo $ cuenta corriente usuarios"
    ];

    const renderSwitch = ({ input }) => (
      <Switch checked={input.value ? true : false} onChange={input.onChange} />
    );

    const renderCheckbox = ({ input, label, disabled }) => (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={input.value ? true : false}
              onChange={input.onChange}
            />
          }
          label={label}
          disabled={disabled}
        />
      </div>
    );

    const disableCatalogoPublico = !this.props.esPublicoValue;
    const disableBeneficioPublico = !this.props.esPublicoValue;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              {disabled ? "Ver " : id === "new" ? "Nuevo " : "Modificar "}
              Programa
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Datos básicos</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={3}>
            <Field
              name="nombre"
              label="Nombre"
              component={TextField}
              className={classes.field}
              validate={required}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl className={classes.field}>
              <InputLabel htmlFor="tipo">Tipo *</InputLabel>
              <Field
                name="tipo"
                label="Tipo"
                component={Select}
                className={classes.field}
                validate={required}
                disabled={disabled}
                required
              >
                <MenuItem value={"CLIENTE"}>Cliente</MenuItem>
                <MenuItem value={"EX-CLIENTE"}>Ex Cliente</MenuItem>
                <MenuItem value={"DEMO"}>Demo</MenuItem>
                <MenuItem value={"PRE-LANZAMIENTO"}>Pre Lanzamiento</MenuItem>
              </Field>
            </FormControl>
          </Grid>
          {id !== "new" ? (
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.field}>
                <InputLabel htmlFor="estado">Estado</InputLabel>
                <Field
                  name="estado"
                  label="Estado"
                  component={Select}
                  className={classes.field}
                  disabled={disabled}
                  required
                >
                  <MenuItem value={"ACTIVO"}>Activo</MenuItem>
                  <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
                </Field>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs />
          )}
          <Grid item xs={12} sm={3}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              name={"fechaLanzamiento"}
              label="Fecha de lanzamiento"
              input={{
                value: this.state.fechaLanzamiento || null,
                onChange: this.handleChangeFecha("fechaLanzamiento")
              }}
              startOfDate
              autoOk
              maxDate={moment()}
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={3}>
            <Field
              name="url"
              label="Url"
              component={TextField}
              className={classes.field}
              validate={isValidUrl}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name="htmlExpectativa"
              label="Url del Html de expectativa"
              component={TextField}
              className={classes.field}
              validate={isValidUrlOrEmpty}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={3}>
            <PaisMultipleField disabled={disabled} required />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MonedaField disabled={disabled} required />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputNumberGtZeroField
              name="tasaConversion"
              label="Tasa de Conversión"
              isFloat
              decimalNumbers={10}
              className={classes.field}
              disabled={disabled}
              required
              InputProps={{
                endAdornment: (
                  <Tooltip title="$ * TC = Puntos">
                    <InputAdornment
                      position="end"
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                    >
                      <InfoIcon />
                    </InputAdornment>
                  </Tooltip>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name="urlContenidos"
              label="Url de contenidos"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="esPublico">Prelogin</InputLabel>
            <Field
              name="esPublico"
              component={RadioGroup}
              validate={required}
              style={{ flexDirection: "row" }}
              parse={value => value === "true"}
              disabled={disabled}
            >
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Activo"
                disabled={disabled}
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Inactivo"
                disabled={disabled}
              />
            </Field>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="catalogoPublico">
              Privacidad del catálogo
            </InputLabel>
            <Field
              name="catalogoPublico"
              component={RadioGroup}
              validate={required}
              style={{ flexDirection: "row" }}
              parse={value => value === "true"}
              disabled={disabled || disableCatalogoPublico}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Abierto"
                disabled={disabled || disableCatalogoPublico}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Cerrado"
                disabled={disabled || disableCatalogoPublico}
              />
            </Field>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="conFormulario">
              Formulario de registro
            </InputLabel>
            <Field
              name="conFormulario"
              component={RadioGroup}
              validate={required}
              style={{ flexDirection: "row" }}
              parse={value => value === "true"}
              disabled={disabled}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Activo"
                disabled={disabled}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Inactivo"
                disabled={disabled}
              />
            </Field>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="beneficioPublico">
              Privacidad de Beneficios
            </InputLabel>
            <Field
              name="beneficioPublico"
              component={RadioGroup}
              validate={required}
              style={{ flexDirection: "row" }}
              parse={value => value === "true"}
              disabled={disabled || disableBeneficioPublico}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Abierto"
                disabled={disabled || disableBeneficioPublico}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Cerrado"
                disabled={disabled || disableBeneficioPublico}
              />
            </Field>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl className={classes.field}>
            <InputLabel htmlFor="conUsername">
              Validación en nombre del usuario.
            </InputLabel>
            <Field
              name="conUsername"
              component={Select}
              className={classes.field}
              disabled={disabled}
              required
            >
              <MenuItem value={"Numeros"}>Números, sin espacios.</MenuItem>
              <MenuItem value={"NumerosLetras"}>
                Números, letras y espacios.
              </MenuItem>
              <MenuItem value={"Default"}>
                Números, letras (A-Z) y simbolos ( . _ & @ - ) sin espacios.
              </MenuItem>
              <MenuItem value={"Extendido"}>
                Números, letras, vocales con acentos y simbolos ( . _ & @ - ' /
                !), con espacios.
              </MenuItem>
            </Field>
          </FormControl>
        </Grid>

        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Datos del cliente
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={4}>
            <Field
              name="razonSocial"
              label="Razón Social"
              component={TextField}
              className={classes.field}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="nombreFantasia"
              label="Nombre fantasía"
              component={TextField}
              className={classes.field}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="cuit"
              label="CUIT de la empresa"
              component={TextField}
              className={classes.field}
              disabled={disabled}
              required
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={4}>
            <Field
              name="telefonoContacto"
              label="Teléfono de contacto"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="emailContacto"
              label="Email contacto"
              component={TextField}
              className={classes.field}
              validate={email}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="emailRemitente"
              label="Email de notificaciones"
              component={TextField}
              className={classes.field}
              validate={email}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Datos del programa
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={3}>
            <InputNumberField
              name="diasParaVencimientoPuntos"
              helperText="Vencimiento de puntos (días)"
              label="Días para vencimiento"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputNumberField
              name="diasParaNotificacionVencimientoPuntos"
              helperText="Notificación de vencimiento (días)"
              label="Notif. Vencimiento"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputNumberField
              name="maximoMovimientoPuntos"
              helperText="Máximo crédito puntos CC Usuarios"
              label="Máx. puntos"
              component={TextField}
              className={classes.field}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputNumberField
              name="maximoMovimientoPlata"
              helperText="Máximo crédito $ CC Programa"
              label="Monto máx."
              isFloat
              component={TextField}
              className={classes.field}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="conceptoCanje"
              label="Concepto de canjes"
              component={TextField}
              className={classes.field}
              disabled={disabled}
              helperText={
                "Usar <producto> y <cantidad> para incluirlos en el concepto."
              }
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Datos adicionales
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={12}>
            <Field
              name="avisoCumpleJefe"
              component={renderCheckbox}
              label="Avisar el jefe de un usuario cuando cumple años"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid className={classes.grid} container style={{ margin: 0 }}>
              <Grid item xs={12} sm={5}>
                <Field
                  name="avisoCumpleCompaneros"
                  component={renderCheckbox}
                  label={
                    definicionesPerfil.length === 0
                      ? "Avisar los compañeros (El programa tiene que tener Definiciones de perfil para esta opción)"
                      : "Avisar los compañeros de un usuario cuando cumple años"
                  }
                  disabled={disabled || definicionesPerfil.length === 0}
                />
              </Grid>
              {avisoCumpleCompaneros && (
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.field}>
                    <InputLabel htmlFor="identificarCompanerosPor">
                      Identificar compañeros por:
                    </InputLabel>
                    <Field
                      name="identificarCompanerosPor"
                      label="Identificar compañeros por:"
                      component={Select}
                      className={classes.field}
                      validate={required}
                      disabled={disabled}
                    >
                      {definicionesPerfil.map((definicion, i) => (
                        <MenuItem key={i} value={definicion.key}>
                          {definicion.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={classes.grid} container style={{ margin: 0 }}>
              <Grid item xs={12} sm={5}>
                <Field
                  name="cronExportacionUsuarios"
                  component={renderCheckbox}
                  label={
                    "Exportar los usuarios del programa automáticamente diariamente"
                  }
                  disabled={disabled}
                />
              </Grid>
              {cronExportacionUsuarios && (
                <Grid item xs={12} sm={6}>
                  <Field
                    name="emailsExportacionUsuarios"
                    label="Enviar el csv de exportación a:"
                    component={TextField}
                    className={classes.field}
                    disabled={disabled}
                    helperText={"Separar los mails por coma"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={classes.grid} container style={{ margin: 0 }}>
              <Grid item xs={12} sm={5}>
                <Field
                  name="esNotificacionStockBajo"
                  component={renderCheckbox}
                  label={
                    "Notificar usuarios del cliente en situación de stock bajo de un determinado producto."
                  }
                  disabled={disabled}
                />
              </Grid>
              {esNotificacionStockBajo && (
                <Grid item xs={12} sm={6}>
                  <Field
                    name="emailsNotificacionStockBajo"
                    label="Enviar notificación vía correo electrónico de stock bajo a:"
                    component={TextField}
                    className={classes.field}
                    disabled={disabled}
                    helperText={"Separar los mails por coma"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              type="textarea"
              name="comentarios"
              label="Comentarios"
              component={TextField}
              multiline
              className={classes.field}
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Indicadores de reporte
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={12}>
            <List>
              {indicadores.map((value, index) => (
                <ListItem key={index} dense button>
                  <ListItemText primary={`${value}`} />
                  <Field
                    name={`indicadoresSeleccionados['${value}']`}
                    color="primary"
                    component={renderSwitch}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Módulos
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={12}>
            <List>
              {grupos.map((value, index) => (
                <Fragment key={index}>
                  <ListItem dense button>
                    <ListItemText
                      primary={`${value.nombre}`}
                      secondary={value.descripcion}
                    />
                    <Field
                      name={`modulosSeleccionados['${value.nombre}']`}
                      color="primary"
                      component={renderSwitch}
                      onChange={({ target }) => {
                        if (value.nombre === "Chat Online") {
                          this.setState({
                            showChatInputCode: target.checked
                          });
                        }
                      }}
                    />
                  </ListItem>

                  {value.nombre === "Chat Online" &&
                    this.state.showChatInputCode && (
                      <ListItem>
                        <Grid item sm={3}>
                          <Field
                            name="codigoChatOnline"
                            label={
                              <>
                                <span>JivoChat widget id</span>{" "}
                                <a
                                  target="_blank"
                                  href="https://docs.google.com/document/d/1y11nznie7ya6iDtOoR4hS3emGnzfsrS9Kc8v8oOyFSE"
                                >
                                  (?)
                                </a>
                              </>
                            }
                            component={TextField}
                            className={classes.field}
                            validate={required}
                            required
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            name="chatSinLogin"
                            component={renderCheckbox}
                            label="Activar chat sin iniciar sesión"
                            disabled={disabled}
                          />
                        </Grid>
                      </ListItem>
                    )}
                </Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
        <ErrorList errors={errors} />
        <Grid className={classes.grid} container>
          <Grid item xs />
          <Grid item xs />
          <Grid className={classes.gridButton} item xs>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => history.push("/admin/programa")}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid className={classes.gridButton} item xs>
            {disabled && (
              <Button
                type="button"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => history.push("/admin/programa/" + id + "/edit")}
              >
                Editar
              </Button>
            )}
            {!disabled && (
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                disabled={
                  (pristine && this.state.fechaLanzamientoNotChanged) ||
                  submitting
                }
              >
                Guardar
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formProgramas",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formProgramas");
Form = connect(state => {
  const esPublicoValue = selector(state, "esPublico");
  const avisoCumpleCompaneros = selector(state, "avisoCumpleCompaneros");
  const cronExportacionUsuarios = selector(state, "cronExportacionUsuarios");
  const esNotificacionStockBajo = selector(state, "esNotificacionStockBajo");
  return {
    esPublicoValue,
    avisoCumpleCompaneros,
    cronExportacionUsuarios,
    esNotificacionStockBajo
  };
})(Form);

export default withRouter(withStyles(styles)(Form));
