export default {
  // notification: {
  //   backgroundColor: theme.palette.secondary.main,
  //   color: theme.palette.getContrastText(theme.palette.secondary.main)
  // },
  // root: {
  //   overflow: "visible",
  //   paddingRight: theme.spacing.unit,
  //   minHeight: 0,
  //   width: "300px"
  // },
  formControl: {
  },
  dialogContent: {
    overflow: "visible"
  },
  dialogTitle: {},
  imagenCapture: {
    with: "100%"
  },
  textField: {
    with: "100%"
  }
};
