import gql from "graphql-tag";

export const importarProveedores = gql`
  mutation importProveedores($file: Upload!) {
    importProveedores(file: $file)
  }
`;

export const importarUsuarios = gql`
  mutation importUsuarios($file: Upload!, $idPrograma: Int!) {
    importUsuarios(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarJerarquia = gql`
  mutation importJerarquia($file: Upload!, $idPrograma: Int!) {
    importJerarquia(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarSubrubros = gql`
  mutation importSubrubros($file: Upload!, $idPrograma: Int!) {
    importSubrubros(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarUbicaciones = gql`
  mutation importUbicaciones($file: Upload!, $idPrograma: Int!) {
    importUbicaciones(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarPerfiles = gql`
  mutation importPerfiles($file: Upload!, $idPrograma: Int) {
    importPerfiles(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarSucursales = gql`
  mutation importSucursales($file: Upload!) {
    importSucursales(file: $file)
  }
`;

export const importarProductos = gql`
  mutation importProductos($file: Upload!) {
    importProductos(file: $file)
  }
`;

export const importarAsignarPuntosAUsuario = gql`
  mutation importAsignarPuntosAUsuario($file: Upload!, $idPrograma: Int!) {
    importAsignarPuntosAUsuario(file: $file, idPrograma: $idPrograma)
  }
`;

export const parseCodigosActivacion = gql`
  mutation parseCodigosActivacion($file: Upload!) {
    parseCodigosActivacion(file: $file) {
      codigo
      estado
    }
  }
`;

export const importarCodigosActivacion = gql`
  mutation importCodigosActivacion($file: Upload!, $idProducto: Int!) {
    importCodigosActivacion(file: $file, idProducto: $idProducto)
  }
`;
export const importarContenidos = gql`
  mutation importarContenidos($file: Upload!, $idPrograma: Int!) {
    importarContenidos(file: $file, idPrograma: $idPrograma)
  }
`;
export const importarCatalogoSegmento = gql`
  mutation importCatalogoSegmento($file: Upload, $idPrograma: Int!) {
    importCatalogoSegmento(file: $file, idPrograma: $idPrograma)
  }
`;
export const importarCatalogoUbicacion = gql`
  mutation importCatalogoUbicacion($file: Upload, $idPrograma: Int!) {
    importCatalogoUbicacion(file: $file, idPrograma: $idPrograma)
  }
`;
export const importarCatalogoCategoria = gql`
  mutation importCatalogoCategoria($file: Upload, $idPrograma: Int!) {
    importCatalogoCategoria(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarGrupoSKU = gql`
  mutation importGrupoSKU(
    $file: Upload
    $idPrograma: Int!
    $nombre: String!
    $idGrupo: ID
  ) {
    importGrupoSKU(
      file: $file
      idPrograma: $idPrograma
      nombre: $nombre
      idGrupo: $idGrupo
    ) {
      productos {
        sku
        descripcion
      }
      nombre
    }
  }
`;

export const importarTargetsUsuario = gql`
  mutation importTargetsUsuario($file: Upload!, $idCampana: ID!) {
    importTargetsUsuario(file: $file, idCampana: $idCampana)
  }
`;

export const importarProductosRetail = gql`
  mutation importProductosRetail($file: Upload!, $idPrograma: Int!) {
    importProductosRetail(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarCanalesRetail = gql`
  mutation importCanalesRetail($file: Upload!, $idPrograma: Int!) {
    importCanalesRetail(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarVendedoresCanales = gql`
  mutation importVendedoresCanales(
    $file: Upload!
    $idPrograma: Int!
    $codigoCanal: String
  ) {
    importVendedoresCanales(
      file: $file
      idPrograma: $idPrograma
      codigoCanal: $codigoCanal
    )
  }
`;

export const importarCanalesRetailStock = gql`
  mutation importCanalesRetailStock(
    $file: Upload!
    $idPrograma: Int!
    $codigoCanal: String
  ) {
    importCanalesRetailStock(
      file: $file
      idPrograma: $idPrograma
      codigoCanal: $codigoCanal
    )
  }
`;

export const importarClientesRetail = gql`
  mutation importClientesRetail($file: Upload!, $idPrograma: Int!) {
    importClientesRetail(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarProductosCodificados = gql`
  mutation importProductosCodificados($file: Upload!, $idPrograma: Int!) {
    importProductosCodificados(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarCodigosQR = gql`
  mutation importCodigosQR($file: Upload!, $idPrograma: Int!) {
    importCodigosQR(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarCodigosBarras = gql`
  mutation importCodigosBarras($file: Upload!, $idPrograma: Int!) {
    importCodigosBarras(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarCodigosSinProductos = gql`
  mutation importCodigosSinProductos($file: Upload!, $idPrograma: Int!) {
    importCodigosSinProductos(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarBeneficios = gql`
  mutation importBeneficios($file: Upload!, $idPrograma: Int!) {
    importBeneficios(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarBeneficioSucursales = gql`
  mutation importBeneficioSucursales($file: Upload!, $idPrograma: Int!) {
    importBeneficioSucursales(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarBeneficioSegmentos = gql`
  mutation importBeneficiosSegmentos($file: Upload!, $idPrograma: Int!) {
    importBeneficiosSegmentos(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarBeneficioRubros = gql`
  mutation importBeneficiosRubros($file: Upload!, $idPrograma: Int!) {
    importBeneficiosRubros(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarBeneficioUbicaciones = gql`
  mutation importBeneficiosUbicaciones($file: Upload!, $idPrograma: Int!) {
    importBeneficiosUbicaciones(file: $file, idPrograma: $idPrograma)
  }
`;
// solapa para catalogo codigos

export const importCatalogoCodigos = gql`
  mutation importCatalogoCodigos($file: Upload!, $idPrograma: Int!) {
    importCatalogoCodigos(file: $file, idPrograma: $idPrograma)
  }
`;

export const importCatalogoCodigosSucursales = gql`
  mutation importCatalogoCodigosSucursales($file: Upload!, $idPrograma: Int!) {
    importCatalogoCodigosSucursales(file: $file, idPrograma: $idPrograma)
  }
`;

export const importCatalogoCodigosSegmentos = gql`
  mutation importCatalogoCodigosSegmentos($file: Upload!, $idPrograma: Int!) {
    importCatalogoCodigosSegmentos(file: $file, idPrograma: $idPrograma)
  }
`;

export const importCatalogoCodigosRubros = gql`
  mutation importCatalogoCodigosRubros($file: Upload!, $idPrograma: Int!) {
    importCatalogoCodigosRubros(file: $file, idPrograma: $idPrograma)
  }
`;

export const importCatalogoCodigosUbicaciones = gql`
  mutation importCatalogoCodigosUbicaciones($file: Upload!, $idPrograma: Int!) {
    importCatalogoCodigosUbicaciones(file: $file, idPrograma: $idPrograma)
  }
`;

//  termina solapa catalogo codigos

export const importarMeds = gql`
  mutation importMeds($file: Upload!, $idPrograma: Int!) {
    importMeds(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarVariablesMeds = gql`
  mutation importVariablesMeds($file: Upload!, $idPrograma: Int!) {
    importVariablesMeds(file: $file, idPrograma: $idPrograma)
  }
`;

export const importarPreventistasMed = gql`
  mutation importPreventistasMed(
    $file: Upload!
    $idPrograma: Int!
    $codigoMed: String!
  ) {
    importPreventistasMed(
      file: $file
      idPrograma: $idPrograma
      codigoMed: $codigoMed
    )
  }
`;

export default {
  queries: {
    importarProveedores,
    importarSucursales,
    parseCodigosActivacion,
    importarCodigosActivacion,
    importarProductos,
    importarUsuarios,
    importarAsignarPuntosAUsuario,
    importarSubrubros,
    importarUbicaciones,
    importarPerfiles,
    importarJerarquia,
    importarContenidos,
    importarCatalogoSegmento,
    importarCatalogoUbicacion,
    importarCatalogoCategoria,
    importarGrupoSKU,
    importarTargetsUsuario,
    importarProductosRetail,
    importarCanalesRetail,
    importarVendedoresCanales,
    importarCanalesRetailStock,
    importarClientesRetail,
    importarProductosCodificados,
    importarCodigosQR,
    importarCodigosBarras,
    importarCodigosSinProductos,
    importarBeneficios,
    importarBeneficioSucursales,
    importarBeneficioSegmentos,
    importarBeneficioRubros,
    importarBeneficioUbicaciones,
    importarMeds,
    importarVariablesMeds,
    importarPreventistasMed,
    importCatalogoCodigos,
    importCatalogoCodigosSucursales,
    importCatalogoCodigosSegmentos,
    importCatalogoCodigosRubros,
    importCatalogoCodigosUbicaciones
  }
};
