import gql from "graphql-tag";

export const getVentasRetail = gql`
  query getVentasRetail(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VentaRetailColumn!
    $idPrograma: Int
    $idCanal: ID
    $idCliente: ID
    $idUsuario: Int
    $numeroFactura: String
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getVentasRetail(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      ventaOrder: { column: $orderBy, order: $order }
      ventaLike: {
        idPrograma: $idPrograma
        idCanal: $idCanal
        idCliente: $idCliente
        idUsuario: $idUsuario
        numeroFactura: $numeroFactura
      }
      ventaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        usuario {
          username
        }
        cliente {
          _id
          documento
          nombre
        }
        canal {
          codigo
          nombre
        }
        numeroFactura
        total
        fechaCreacion
      }
    }
  }
`;

export const getMisVentasRetail = gql`
  query getMisVentasRetail(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VentaRetailColumn!
    $idPrograma: Int
    $idCliente: ID
    $numeroFactura: String
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getMisVentasRetail(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      ventaOrder: { column: $orderBy, order: $order }
      ventaLike: {
        idPrograma: $idPrograma
        idCliente: $idCliente
        numeroFactura: $numeroFactura
      }
      ventaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        cliente {
          _id
          documento
          nombre
        }
        numeroFactura
        total
        fechaCreacion
      }
    }
  }
`;

export const getVentaRetailById = gql`
  query getVentasRetail($ventaLike: VentaRetailSearch) {
    getVentasRetail(ventaLike: $ventaLike) {
      itemsPagina {
        _id
        cliente {
          _id
          documento
          nombre
        }
        detalles {
          producto {
            _id
            nombre
            codigo
          }
          cantidad
          puntos
          observacion
        }
        numeroFactura
        total
        fechaCreacion
      }
    }
  }
`;

export const getMiVentaRetailById = gql`
  query getMisVentasRetail($ventaLike: VentaRetailSearch) {
    getMisVentasRetail(ventaLike: $ventaLike) {
      itemsPagina {
        _id
        cliente {
          _id
          documento
          nombre
        }
        detalles {
          producto {
            _id
            nombre
            codigo
          }
          cantidad
          puntos
          observacion
        }
        numeroFactura
        total
        fechaCreacion
      }
    }
  }
`;

export const createVentaRetail = gql`
  mutation createVentaRetail($venta: VentaRetailCreate!) {
    createVentaRetail(venta: $venta) {
      _id
    }
  }
`;

export default {
  queries: {
    getVentasRetail,
    getMisVentasRetail,
    getVentaRetailById,
    getMiVentaRetailById
  },
  mutations: {
    createVentaRetail
  }
};
