import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Slide
} from "@material-ui/core";
import { withNotificationContext } from "js/notification-context";
import ImageUploader, { ItemImages } from "js/components/Shared/ImageUploader";
import Grid from "@material-ui/core/Grid";
import { _t } from "js/utils/TranslationService";
import SplashScreen from "js/pages/Loading";
import { addImageURL } from "js/constants";
import { getUrl } from "js/utils";
import { getSize } from "./utils";

const styles = theme => ({
  dialogContent: {
    overflow: "visible"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isLoadingImage: false,
      facturaImages: [],
      error: undefined,
      idPrograma: this.props.match.params.id,
      idUsuario: parseInt(localStorage.getItem("idUsuario"))
    };
  }

  sendValues = async (openModalFunction, handleDisabled) => {
    const { facturaImages } = this.state;

    if (facturaImages.length === 0) {
      const mensaje = "Debe seleccionar una imagen.";
      return this.props.openNotification(mensaje);
    }

    this.setState({
      facturaImages: facturaImages,
      error: undefined
    });
    const values = [openModalFunction(false), handleDisabled(true)];
    return values;
  };

  onChangeImage = async image => {
    const { height, width } = await getSize(image);

    // Creamos un elemento de imagen para cargar la imagen
    const imgElement = new Image();

    imgElement.onload = () => {
      // Creamos un canvas para convertir la imagen a formato jpeg
      const canvas = document.createElement("canvas");
      canvas.width = imgElement.width;
      canvas.height = imgElement.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(imgElement, 0, 0);

      // Convertimos el canvas a formato jpeg
      canvas.toBlob(blob => {
        // Creamos un nuevo archivo Blob con el formato jpeg
        const jpegFile = new File([blob], image.name, { type: "image/jpeg" });

        // Creamos el FormData con el archivo jpeg
        const formData = new FormData();
        formData.append("image", jpegFile);
        formData.append("context", "facturaPorPuntos");
        formData.append("height", height);
        formData.append("width", width);

        const { getUrlFacturaImagen, getFileName } = this.props;

        this.setState({ isLoadingImage: true });

        fetch(addImageURL, {
          method: "POST",
          body: formData
        })
          .then(response => response.json())
          .then(({ url }) => {
            this.setState(({ facturaImages }) => ({
              facturaImages: facturaImages.concat(getUrl(url)),
              isLoadingImage: false
            }));
            getUrlFacturaImagen(this.state.facturaImages);
            getFileName(image.name);
          })
          .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
      }, "image/jpeg");
    };

    imgElement.src = URL.createObjectURL(image);
  };

  onRemoveImage = urlToRemove => {
    this.setState(({ facturaImages }) => ({
      facturaImages: facturaImages.filter(url => url !== urlToRemove)
    }));
  };

  render() {
    const {
      classes,
      open,
      disabled,
      openModalFunction,
      handleDisabled
    } = this.props;

    const { facturaImages } = this.state;

    return (
      <Fragment>
        <Dialog
          open={typeof open === "undefined" ? true : open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            <Grid item xs={12}>
              <ImageUploader
                onChangeImage={this.onChangeImage}
                disableClick={true}
                disabled={disabled || facturaImages.length === 1}
                withRecomendations={false}
              >
                <ItemImages
                  images={facturaImages}
                  onRemoveImage={this.onRemoveImage}
                  disabled={disabled}
                  alt={"imagen-factura"}
                />
              </ImageUploader>
            </Grid>
          </DialogTitle>
          {this.state.isLoadingImage ? (
            <>
              <Grid container item style={{ height: 100 }}>
                <SplashScreen className={classes.progress} />
              </Grid>
            </>
          ) : null}
          <DialogActions>
            <Button
              onClick={() => openModalFunction(false)}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => this.sendValues(openModalFunction, handleDisabled)}
              variant="contained"
              color="primary"
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(connect(null)(Modal));
