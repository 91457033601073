import React from "react";
import {
  CalendarCheck,
  ProgressCheck,
  Receipt,
  CheckCircle
} from "mdi-material-ui";
import { DialogFecha, DialogEstado, DialogFactura } from "./Dialogs";
import { USER_ROLES as Roles } from "js/models/User";
import { DialogAprobarVariablesMed } from "js/pages/Unilever/Dialogs";
export const toolbarMap = new Map([
  [
    "calendar",
    {
      key: "Fecha",
      actionIcon: <CalendarCheck color="primary" />,
      tooltip: "Cambiar fecha de entrega",
      component: DialogFecha,
      perms: [Roles.CANJE_W]
    }
  ],
  [
    "progress",
    {
      key: "Estado",
      actionIcon: <ProgressCheck color="primary" />,
      tooltip: "Cambiar estado",
      component: DialogEstado,
      perms: [Roles.CANJE_W]
    }
  ],
  [
    "asignar-factura",
    {
      text: "Asignar Factura",
      key: "asignarFactura",
      tooltip: "Asignar Factura",
      actionIcon: <Receipt color="primary" />,
      component: DialogFactura,
      perms: [Roles.FACTURA_W]
    }
  ],
  [
    "estado_variables_meds_unilever",
    {
      key: "Estado",
      actionIcon: <CheckCircle color="primary" />,
      tooltip: "Aprobar targets",
      component: DialogAprobarVariablesMed,
      perms: [Roles.MED_W]
    }
  ]
]);

export const getInitialValues = () => {
  const init = {};
  for (let [, value] of toolbarMap) {
    init[`dialog${value.key}`] = false;
  }
  return init;
};
