import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaVentaRetail/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/ventaRetail.resolver";
import { USER_ROLES as Roles } from "js/models/User";
import { viewAction } from "js/components/Shared/Tabla/ActionCells";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Store from "@material-ui/icons/Store";
import ShareIcon from "@material-ui/icons/Share";
import GroupIcon from "@material-ui/icons/Group";
import { columnas, misColumnas } from "js/components/TablaVentaRetail/columns";

const defaultHeader = (id, btnsNextTitle, isMisVentas) => {
  return {
    title: "Ventas Registradas",
    btnsNextTitle,
    new: {
      title: "Nuevo",
      perms: [Roles.VENTA_RETAIL_W]
    },
    export: {
      path: isMisVentas ? "ventasRetail/misVentas" : "ventasRetail",
      tooltip: "Exportar ventas",
      perms: [Roles.VENTA_RETAIL_EXPORT]
    },
    queryStringMatcher: {
      idsPrograma: undefined
    }
  };
};

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });

    const {
      match: {
        params: { id }
      }
    } = this.props;
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    const isMisVentas = window.location.pathname.indexOf("mis") !== -1;
    let columnData = null;

    if (isMisVentas) {
      columnData = misColumnas;
    } else {
      columnData = columnas;
    }

    const btnsNextTitle = [];
    if (inBackOffice) {
      btnsNextTitle.push({
        btn: (
          <Tooltip title="Productos">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(
                  `/admin/programa/${id}/productos-retail`
                )
              }
            >
              <Store />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.PRODUCTO_RETAIL_R]
      });
      btnsNextTitle.push({
        btn: (
          <Tooltip title="Canales">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(`/admin/programa/${id}/canales-retail`)
              }
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.CANAL_RETAIL_R]
      });
      btnsNextTitle.push({
        btn: (
          <Tooltip title="Clientes">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(`/admin/programa/${id}/clientes-retail`)
              }
            >
              <GroupIcon />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.CLIENTE_RETAIL_R]
      });
    }

    return (
      <Table
        storage={"FiltroVentaRetail"}
        query={
          isMisVentas
            ? AppQraphQL.queries.getMisVentasRetail
            : AppQraphQL.queries.getVentasRetail
        }
        defaultHeader={defaultHeader(id, btnsNextTitle, isMisVentas)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data =>
          isMisVentas ? data.getMisVentasRetail : data.getVentasRetail
        }
        actionsCustom={[view]}
        pathId={"_id"}
      />
    );
  }
}

export default Tabla;
