import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import View from "./View";
import LogQraphQL from "js/graphql/resolvers/log.resolver";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";
import { withRouter } from "react-router-dom";
import { renderFechaWithFormat } from "js/utils/Helper";
import capitalize from "lodash/capitalize";
import get from "lodash/get";

import { compose, withProps } from "recompose";

const formatLogs = logs => {
  const log = {
    Concepto: capitalize(logs.concepto),
    Fecha: renderFechaWithFormat("DD/MM/YYYY HH:mm:ss", logs.fecha),
    Ejecutante: {
      Usuario: logs.usuario.username
    },
    "Líneas procesadas": logs.lineasProcesadas,
    "Líneas totales": logs.total,
    Estado: capitalize(logs.estado),
    Errores: logs.errores.map(e => ({
      "Número de Línea": e.linea || "--",
      Descripción: e.error,
      Usuario: get(e, "usuario.username", "--"),
      Programa: get(e, "programa.nombreFantasia", "--")
    })),
    Correctos: logs.correctos.map(c => ({
      Usuario: get(c, "usuario.username", "--"),
      Programa: get(c, "programa.nombreFantasia", "--"),
      Puntos: get(c, "puntos", "--"),
      Movimiento: get(c, "movimientos[0].idMovimiento", "--")
    }))
  };
  if (logs.nombreArchivo) log["Nombre del archivo"] = logs.nombreArchivo;
  if (logs.usuariosNuevos)
    log["Cantidad de usuarios nuevos"] = logs.usuariosNuevos;
  if (logs.usuariosInactivados)
    log["Cantidad de usuarios inactivados"] = logs.usuariosInactivados;
  return log;
};

const enhance = compose(
  withPrograma,
  withStyles(styles),
  withRouter,
  withProps(props => ({
    idLog: props.match.params.idLog
  })),
  graphql(LogQraphQL.queries.getLogsImportacionById, {
    options: ({ programa, idLog }) => ({
      fetchPolicy: "network-only",
      variables: {
        idLog
      }
    }),
    props: ({ data: { loading, ...data } }) => {
      if (loading) return;
      if (window.location.pathname.includes("admin")) {
        let logs = data.getLogsImportacion.itemsPagina[0];
        return { logs: formatLogs(logs) };
      }
    }
  }),
  graphql(LogQraphQL.queries.getMisLogsImportacionById, {
    options: ({ programa, idLog }) => ({
      fetchPolicy: "network-only",
      variables: {
        idLog
      }
    }),
    props: ({ data: { loading, ...data } }) => {
      if (loading) return;
      if (!window.location.pathname.includes("admin")) {
        let logs = data.getMisLogsImportacion.itemsPagina[0];
        return { logs: formatLogs(logs) };
      }
    }
  })
);
export default enhance(View);
